// index.js

import { Elm } from './src/Main.elm'
import { getConfig } from './config.js';
import 'fomantic-ui-css/semantic.min.css';


document.addEventListener("DOMContentLoaded", function() {
    var app = Elm.Main.init({
        node: document.querySelector('main'),
        flags: { "serviceUrl": getConfig()["backend-url"] }
    })

    document.onSignIn = function(googleUser) {
        var profile = googleUser.getBasicProfile();
        var token = googleUser.getAuthResponse().id_token;

        app.ports.toElm.send(token);

        console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
    };






    var apolloClient = require("apollo-client")
    var apolloLinkWS = require('apollo-link-ws')
    var apolloCacheInMemory = require("apollo-cache-inmemory")
    var gql = require('graphql-tag')

    var GRAPHQL_URI = 'gql.genomicscore.be/v1/graphql'

    const getClient = (token) => {
        const wsLink = new apolloLinkWS.WebSocketLink({
            uri: `wss://${GRAPHQL_URI}`,
            options: {
                reconnect: true,
                connectionParams: {
                    headers: {
                        'x-hasura-admin-secret': `${ token }`
                            // Authorization: `Bearer ${ token }`

                    }
                }
            }
        });

        const client = new apolloClient.ApolloClient({
            link: wsLink,
            cache: new apolloCacheInMemory.InMemoryCache({
                addTypename: true
            })
        });
        return client;
    };




    app.ports.createSubscriptionToBookableResources.subscribe(function(data) {
        /* Initiate subscription request */
        var [data, authToken] = data;
        if (authToken.length > 0) {
            getClient(authToken)
                .subscribe({
                    query: gql `${data}`,
                    variables: {}
                })
                .subscribe({
                    next(resp) {
                        app.ports.gotBookableResources.send(resp);
                    },
                    error(err) {
                        console.log('error is');
                        console.log(err);
                    }
                });
        }
    });


});